import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import logo from "../../../assets/img/clarity_logo.png";
import vector from "../../../assets/img/login_vector.svg";
import InputCheckbox from "../form/InputCheckbox";
import InputTextFeild from "../form/InputTextFeild";
import SubmitButton from "../form/SubmitButton";
import { ApiRoutes, AppRoutes } from "../../../config";
import appleIcon from "../../../assets/img/socialmedia/apple.svg";
import googleIcon from "../../../assets/img/socialmedia/google.svg";
import microsoftIcon from "../../../assets/img/socialmedia/microsoft.svg";
// import instaIcon from "../../../assets/img/socialmedia/instagram.svg";
import fbIcon from "../../../assets/img/socialmedia/facebook.svg";
import twitterIcon from "../../../assets/img/socialmedia/twitter.svg";
import { ApiHelper, setUserData } from "../../../helpers";

import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getIdToken,
  TwitterAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";

const LoginFormComponent = ({
  onChange,
  onSubmit,
  email,
  password,
  errors,
  isLoading,
  islogged,
}) => {
  const history = useHistory();
  const handleSubmit = async (data) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SOCIAL_LOGIN_AUTH.service,
      ApiRoutes.SOCIAL_LOGIN_AUTH.url,
      ApiRoutes.SOCIAL_LOGIN_AUTH.method,
      ApiRoutes.SOCIAL_LOGIN_AUTH.authenticate,
      undefined,
      { idToken: data }
    );
    console.log(response);
    if (response && response.isError) {
      toast.error(response?.messages[0]);
    } else {
      toast.success(response.messages[0]);
      const loginData = response?.data?.data;
      setUserData(loginData);
      history.push(AppRoutes.DASHBOARD);
    }
  };

  const socialLogin = (type) => {
    const auth = getAuth();
    var provider = null;
    var authProvider = null;
    switch (type) {
      case "google":
        authProvider = GoogleAuthProvider;
        provider = new GoogleAuthProvider();
        break;
      case "apple":
        authProvider = OAuthProvider;
        provider = new OAuthProvider();

        break;
      case "facebook":
        authProvider = FacebookAuthProvider;
        provider = new FacebookAuthProvider();
        break;

      case "twitter":
        authProvider = TwitterAuthProvider;
        provider = new TwitterAuthProvider();
        break;

      case "microsoft":
        authProvider = OAuthProvider;
        provider = new OAuthProvider("microsoft.com");
        break;
      default:
        break;
    }
    if (provider) {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = authProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const idToken = credential.idToken;

          const user = result.user;
          getIdToken(user).then((token) => {
            handleSubmit(token);
            console.log("token2", token);
          });
          // ...
        })
        .catch((error) => {
          console.log("error", error);
          // toast.error(error.message);

          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = authProvider.credentialFromError(error);

          // ...
        });
    } else {
      console.log("Please select a provider.");
    }
  };
  console.log(errors);
  return (
    <>
      <div>
        <section className="formLeftSection">
          <div className="glassEffects">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <img className="logo" src={logo} />
                  <h2>Welcome Folks!</h2>
                  <p>
                    You can sign in to your account by entering the below
                    details
                  </p>
                  <Form onSubmit={onSubmit}>
                    <div className="">
                      <label>Email</label>
                      <InputTextFeild
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.email ? errors.email : ""
                        }
                      />
                    </div>

                    <div className="">
                      <label>Password</label>
                      <InputTextFeild
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.password ? errors.password : ""
                        }
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-between loginforgorpassOpt">
                      <InputCheckbox
                        label="Remember Me"
                        id="customCheck"
                        name="islogged"
                        value={islogged}
                      />
                      <Link
                        to={AppRoutes.FORGOT_PASSWORD}
                        style={{ opacity: "0.8" }}
                      >
                        Forgot Password?
                      </Link>
                    </div>

                    <SubmitButton
                      text={"Sign In"}
                      isLoading={isLoading}
                      // onSubmit={onSubmit}
                      className={"customSubmitBtn"}
                    >
                      Sign In
                    </SubmitButton>
                  </Form>
                  <div className="socialLogin" style={{ marginTop: "30px" }}>
                    <h3>Sign in with Social Media</h3>
                    <div style={{ marginTop: "25px" }}>
                      <a
                        href="#"
                        // onClick={() => socialLogin("apple")}
                        className="mr-10px"
                      >
                        <img src={appleIcon} />
                      </a>
                      <a
                        href="#"
                        onClick={() => socialLogin("google")}
                        className="mr-10px"
                      >
                        <img className="myshadow" src={googleIcon} />
                      </a>
                      <a
                        href="#"
                        onClick={() => socialLogin("microsoft")}
                        className="mr-10px"
                      >
                        <img src={microsoftIcon} />
                      </a>
                      {/* <a href="#" className="mr-10px">
                          <img className="myshadow" src={instaIcon} />
                        </a> */}
                      {/* <a
                        href="#"
                        className="mr-10px"
                        onClick={() => socialLogin("facebook")}
                      >
                        <img src={fbIcon} />
                      </a> */}
                      <a
                        href="#"
                        // onClick={() => socialLogin("twitter")}
                        className="mr-10px"
                      >
                        <img src={twitterIcon} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-none d-md-block image-loader">
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <img className="w-100" src={vector} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LoginFormComponent;
