import React, { useState } from "react";
import info_btn from "../../../../assets/img/info_btn.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import appleIcon from "../../../../assets/img/socialmedia/apple.svg";
import googleIcon from "../../../../assets/img/socialmedia/google.svg";
import microsoftIcon from "../../../../assets/img/socialmedia/microsoft.svg";
import fbIcon from "../../../../assets/img/socialmedia/facebook.svg";
import twitterIcon from "../../../../assets/img/socialmedia/twitter.svg";
import trash from "../../../../assets/img/trash-22.svg";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getIdToken,
  TwitterAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { useEffect } from "react";
import SweetAlertComponent from "../../sweetAlertComponent";
import { BatchHistoryLoader } from "../../loader/Resusable/BatchHistory";

function SocialMedia(props) {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        View and manage the list of accounts that are linked with your Clarity
        client account.
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    getSocailLogin();
  }, []);

  const [socialdetails, setSocialDetails] = useState();
  const [deleteLoader, setDeleteLoader] = useState();
  const [pageLoader, setPageLoader] = useState();

  const getSocailLogin = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SOCIAL_LOGIN_DETAILS.service,
      ApiRoutes.SOCIAL_LOGIN_DETAILS.url,
      ApiRoutes.SOCIAL_LOGIN_DETAILS.method,
      ApiRoutes.SOCIAL_LOGIN_DETAILS.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setSocialDetails(data);
    }
  };

  const dleteSocialAccount = async (data) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure you want to delete this account?`,
    });
    if (!value) {
      return;
    }
    setDeleteLoader(data.id);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SOCIAL_LOGIN_DETAILS.service,
      ApiRoutes.DELETE_SOCIAL_LOGIN_DETAILS.url.replace(":id", data.id),
      ApiRoutes.DELETE_SOCIAL_LOGIN_DETAILS.method,
      ApiRoutes.DELETE_SOCIAL_LOGIN_DETAILS.authenticate,
      undefined
    );
    setDeleteLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      getSocailLogin();
    } else {
      toast.success(response.messages[0]);
      getSocailLogin();
    }
  };

  const getProvider = (type) => {
    var imgReturn = null;
    switch (type) {
      case "google.com":
        imgReturn = <img src={googleIcon} />;
        break;
      case "facebook.com":
        imgReturn = <img src={fbIcon} />;
        break;
      case "microsoft.com":
        imgReturn = <img src={microsoftIcon} />;
        break;
      case "apple.com":
        imgReturn = <img src={appleIcon} />;
        break;
      case "twitter.com":
        imgReturn = <img src={twitterIcon} />;
        break;
      default:
        break;
    }
    return imgReturn;
  };

  const handleSubmit = async (data) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SOCIAL_LOGIN.service,
      ApiRoutes.SOCIAL_LOGIN.url,
      ApiRoutes.SOCIAL_LOGIN.method,
      ApiRoutes.SOCIAL_LOGIN.authenticate,
      undefined,
      { idToken: data }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      getSocailLogin();
    }
  };

  const socialLogin = (type) => {
    const auth = getAuth();
    var provider = null;
    var authProvider = null;
    switch (type) {
      case "google":
        authProvider = GoogleAuthProvider;
        provider = new GoogleAuthProvider();
        break;
      case "apple":
        authProvider = OAuthProvider;
        provider = new OAuthProvider("apple.com");

        break;
      case "facebook":
        authProvider = FacebookAuthProvider;
        provider = new FacebookAuthProvider();
        break;

      case "twitter":
        authProvider = TwitterAuthProvider;
        provider = new TwitterAuthProvider();
        break;

      case "microsoft":
        authProvider = OAuthProvider;
        provider = new OAuthProvider("microsoft.com");
        break;
      default:
        break;
    }

    if (provider) {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = authProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const idToken = credential.idToken;
          const user = result.user;
          getIdToken(user).then((token) => {
            handleSubmit(token);
          });
          // ...
        })
        .catch((error) => {
          // toast.error(error.message);
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = authProvider.credentialFromError(error);

          // ...
        });
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {pageLoader ? (
            <BatchHistoryLoader />
          ) : (
            <div
              className="productFormBox passSettings SocialMediaCard"
              style={{ marginBottom: "30px", marginTop: "55px" }}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ borderBottom: "1px solid #F1F4F3" }}
              >
                <h4 style={{ borderBottom: "none" }}>Social Media accounts</h4>
                <OverlayTrigger placement="left" overlay={popover}>
                  <Button className="infoBtn" variant="">
                    <img src={info_btn} />
                  </Button>
                </OverlayTrigger>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col-md-12">
                      <table className="table SocialloginDetails">
                        <tbody>
                          {socialdetails?.length > 0 ? (
                            socialdetails?.map((info, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center socialProfile">
                                    {getProvider(info.providerId)}
                                    <div style={{ marginLeft: "12px" }}>
                                      <h6>
                                        {info.email
                                          ? info.email
                                          : info.firstName +
                                            " " +
                                            info.lastName}
                                      </h6>
                                      <p>
                                        Add since {info.signInTimeDate}{" "}
                                        {info.signInTimeFormat}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{info.browser}</td>
                                <td>
                                  {info.city}, {info.state}, {info.country}
                                </td>
                                <td>
                                  <button
                                    className="trashBtn"
                                    disabled={deleteLoader}
                                    onClick={() => dleteSocialAccount(info)}
                                  >
                                    {deleteLoader === info.id ? (
                                      <i className="fa fa-spinner fa-spin test-spin"></i>
                                    ) : (
                                      <img
                                        style={{ width: "20px" }}
                                        src={trash}
                                      />
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={8}
                                className="text-center image-no-data-xs pt-5 pb-2"
                              >
                                <>
                                  <p>
                                    You haven't linked your social media account
                                    yet
                                  </p>
                                </>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <h5 style={{ marginTop: "30px" }}>Add Account with</h5>
                      <div className="socialLogin">
                        <div className="text-center">
                          <a
                            href="#"
                            // onClick={() => socialLogin("apple")}
                            className="mr-30px"
                          >
                            <img src={appleIcon} />
                          </a>
                          <a
                            href="#"
                            onClick={() => socialLogin("google")}
                            className="mr-30px"
                          >
                            <img className="myshadow" src={googleIcon} />
                          </a>
                          <a
                            href="#"
                            onClick={() => socialLogin("microsoft")}
                            className="mr-30px"
                          >
                            <img src={microsoftIcon} />
                          </a>
                          {/* <a href="#" className="mr-30px">
                          <img className="myshadow" src={instaIcon} />
                        </a> */}
                          {/* <a
                            href="#"
                            onClick={() => socialLogin("facebook")}
                            className="mr-30px"
                          >
                            <img src={fbIcon} />
                          </a> */}
                          <a
                            href="#"
                            //  onClick={() => socialLogin("twitter")}
                          >
                            <img src={twitterIcon} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
